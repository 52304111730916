/* eslint-disable @typescript-eslint/member-ordering */
import { SAmDoctorProfessional } from './../models/schedulesamount.model';
import { HealthUnit, Professional, ProfessionalType } from './../models/schedules.model';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Plan } from '../models/insurances.model';
import { ClinicalUnit, Doctor, Specialty } from '../models/schedules.model';
import { SAmSchedule } from '../models/schedulesamount.model';
import { SchedulesAmountService } from './backend/schedulesamount.service';
import { MessageService } from './message.service';

export interface ScheduleParams {
  month?: number;
  year?: number;
  day?: number;
  healthUnit?: HealthUnit;
  clinicalUnit?: ClinicalUnit;
  specialty?: Specialty;
  plan: Plan;
  doctor?: Doctor;
  professional?: Professional;
  professionalType?: ProfessionalType;
}

@Injectable({
  providedIn: 'root',
})
export class ScheduleStepsService {
  private parameters = new BehaviorSubject<any>(null);
  private schedules = new BehaviorSubject<SAmSchedule>(null);
  private doctorProfessional = new BehaviorSubject<SAmDoctorProfessional>(null);
  private clinicalUnit = new BehaviorSubject<any>(null);

  parameters$ = this.parameters.asObservable();
  schedules$ = this.schedules.asObservable();
  doctorProfessional$ = this.doctorProfessional.asObservable();
  clinicalUnit$ = this.clinicalUnit.asObservable();

  constructor(
    private schedulesAmountService: SchedulesAmountService,
    private messageService: MessageService
  ) { }

  initialize() {
    this.parameters
      .next(null);

    this.schedules
      .next(null);

    this.doctorProfessional
      .next(null);

    this.clinicalUnit
      .next(null);
  }

  setSchedules(schedules) {
    this.schedules.next(schedules);
  }

  setParams(params) {
    this.parameters.next(params);
  }

  setDoctor(doctorProfessional: SAmDoctorProfessional) {
    this.doctorProfessional.next(doctorProfessional);
  }

  setClinicalUnit(clinicalUnit) {
    this.clinicalUnit.next(clinicalUnit);
  }

  loadSchedules(year: number, month: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.parameters$
        .pipe(take(1))
        .subscribe(
          (params) => {
            if (params) {
              this.schedulesAmountService.getSchedules(
                year,
                month,
                params.healthUnit?.id,
                params.clinicalUnit?.id,
                params.plan.id,
                params.doctor && params.doctor !== undefined ? params.doctor?.id : '',
                params.specialty && params.specialty !== undefined ? params.specialty?.id : '',
                params.professional && params.professional !== undefined ? params.professional?.id : '',
                params.professionalType && params.professionalType !== undefined ? params.professionalType?.id : '',
              )
                .pipe(take(1))
                .subscribe(
                  (schedules) => resolve(this.setSchedules(schedules)),
                  (error) => reject(
                    this.messageService.warning(
                      error && error.error && error.error.reason
                        ? error.error.reason
                        : ''
                    )
                  )
                );
            }
          },
          (error) => reject(
            this.messageService.warning(
              error && error.error && error.error.reason ? error.error.reason : ''
            )
          )
        );
    });
  }
}
