/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CompletePatientRegistration, PatientDetails } from '../../models/patient.model';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  private readonly API       = `${environment.api}`;
  private readonly CLINIC_ID = `${environment.clinic.id}`;

  constructor(private http: HttpClient) { }

  completePatientRegistration(data) {
    return this.http.post(`${this.API}/patients`, data)
    .pipe(
      take(1)
    );
  }

  me() {
    return this.http.get<PatientDetails>(`${this.API}/patients/me`)
    .pipe(
      take(1)
    );
  }

  updatePatient(data) {
    return this.http.post(`${this.API}/account/update`, data)
    .pipe(
      take(1)
    );
  }
}
