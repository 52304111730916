import { PaymentService } from './../services/backend/payment.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Payment } from '../models/payment.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentResolverService implements Resolve<any> {

  constructor(private paymentService: PaymentService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.paymentService.listByAppointment(route.paramMap.get('appointmentId'))
                .pipe(
                  map((res: Payment[]) => res[0])
                );
  }
}
