import { ClinicStore } from './../../store/clinic.store';
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { DoctorSpecialty, Specialty } from '../../models/schedules.model';
import { environment } from '../../../environments/environment';
import { SAmSchedule } from '../../models/schedulesamount.model';

@Injectable({
  providedIn: 'root',
})
export class SchedulesAmountService {
  private readonly API       = `${environment.api}`;
  private readonly CLINIC_ID = `${environment.clinic.id}`;

  constructor(private http: HttpClient, private clinicStore: ClinicStore) { }

  getSchedules(year: number, month: number, healthUnitId: string, clinicalUnitId: string,planId: string, doctorId: string,
    specialtyId: string, professionalId: string, professionalTypeId: string
  ) {
    if (this.clinicStore.aggregateMode) {
      return this.getAggregateSchedulesAmount( year, month, healthUnitId, planId,
        doctorId, specialtyId, professionalId, professionalTypeId
      );
    } else {
      return this.getSchedulesAmount( year, month, clinicalUnitId, planId,
        doctorId, specialtyId, professionalId, professionalTypeId
      );
    }
  }

  private getSchedulesAmount( year: number, month: number, clinicalUnitId: string, planId: string,
    doctorId: string, specialtyId: string, professionalId: string, professionalTypeId: string
  ) {
    return this.http
      .get<SAmSchedule>(
        `${this.API}/schedulesamount/schedules?month=${month}&year=${year}&clinicId=${this.CLINIC_ID}&clinicalUnitId=${clinicalUnitId}&planId=${planId}&specialtyId=${specialtyId}&doctorId=${doctorId}&professionalId=${professionalId}&professionalTypeId=${professionalTypeId}`
      )
      .pipe(take(1));
  }

  private getAggregateSchedulesAmount( year: number, month: number, healthUnitId: string, planId: string,
    doctorId: string, specialtyId: string, professionalId: string, professionalTypeId: string
  ) {
    return this.http
      .get<SAmSchedule>(
        `${this.API}/schedulesamount/schedulesaggregate?month=${month}&year=${year}&clinicId=${this.CLINIC_ID}&healthUnitId=${healthUnitId}&planId=${planId}&specialtyId=${specialtyId}&doctorId=${doctorId}&professionalId=${professionalId}&professionalTypeId=${professionalTypeId}`
      )
      .pipe(take(1));
  }
}
