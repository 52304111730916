/* eslint-disable @typescript-eslint/naming-convention */
import { User } from './../../models/user.model';
import { UserStore } from './../../store/user.store';
import { StorageService } from './../storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Appointment } from '../../models/appointments.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  private readonly API       = `${environment.api}`;
  private readonly CLINIC_ID = `${environment.clinic.id}`;
  private readonly account_id = `${this.userStore.id}`;
  private appointment = new BehaviorSubject<any>(null);

  appointment$ = this.appointment.asObservable();

  constructor(private http: HttpClient, private userStore: UserStore) {}

  setAppointment(appointment) {
    this.appointment.next(appointment)
  }

  list() {
    return this.http.get<Appointment[]>(`${this.API}/appointments?clinicId=${this.CLINIC_ID}&patientId=${this.userStore.id}`)
    .pipe(
      take(1)
    );
  }

  register(data) {
    return this.http.post(`${this.API}/appointment/create`, data)
    .pipe(
      take(1)
    );
  }

  confirm(appointmentId) {
    return this.http.put(`${this.API}/appointment/confirm/${appointmentId}`, {})
    .pipe(
      take(1)
    );
  }

  cancel(appointmentId) {
    return this.http.put(`${this.API}/appointment/cancel/${appointmentId}`, {})
    .pipe(
      take(1)
    );
  }

  get(appointmentId) {
    return this.http.get(`${this.API}/appointments/${appointmentId}`)
    .pipe(
      take(1)
    );
  }

  getByPatientId(): Observable<any> {
    return this.http.get(`${this.API}/appointment/all`)
    .pipe(
      take(1)
    );
  }

  getAvaiableTimes(data): Observable<any> {
    return this.http.post(`${this.API}/appointment/get_avaiable_times`, data)
    .pipe(
      take(1)
    );
  }
}
