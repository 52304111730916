/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Plan } from '../../models/insurances.model';
import { environment } from '../../../environments/environment';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {

  private readonly API       = `${environment.api}`;
  private readonly CLINIC_ID = `${environment.clinic.id}`;

  constructor(private http: HttpClient) {}

  getPlans() {
    return this.http.get<any>(`${this.API}/units/insurances/all`)
      .pipe(
        take(1)
      );
  }

  getPlan(id: string) {
    return this.http.get<Plan>(`${this.API}/insurances/plans/${id}`)
      .pipe(
        take(1)
      );
  }
}
