/* eslint-disable @typescript-eslint/naming-convention */
import {
  ClinicalUnit,
  HealthUnit,
  HealthUnitRelationship,
  ClinicalUnitPlanRelationship,
  DoctorProfessional,
  SpecialtyProfessionalType,
  Professional
} from './../../models/schedules.model';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Doctor, DoctorSpecialty, Specialty } from '../../models/schedules.model';
import { environment } from '../../../environments/environment';
import { ClinicStore } from 'src/app/store/clinic.store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SchedulesService {

  private readonly API = `${environment.api}`;
  private readonly CLINIC_ID = `${environment.clinic.id}`;
  private readonly unit_id = `${this.clinicStore.clinicId}`;

  constructor(private http: HttpClient, private clinicStore: ClinicStore) { }

  getClinicalUnits() {
    return this.http.get<ClinicalUnit[]>(`${this.API}/schedules/${this.CLINIC_ID}/clinicalUnits`)
      .pipe(
        take(1)
      );
  }

  getDoctors() {
    return this.http.get<Doctor[]>(`${this.API}/schedules/${this.CLINIC_ID}/doctors`)
      .pipe(
        take(1)
      );
  }

  getProfessionals():Observable<any> {
    return this.http.get(`${this.API}/professional/unit/${this.unit_id}`)
      .pipe(
        take(1)
      );
  }

  getProfessionalsBySpeciality(unit_id, specialty_id): Observable<any> {
    return this.http.get(`${this.API}/professional/unit/${unit_id}/specialty/${specialty_id}`)
      .pipe(
        take(1)
      );
  }

  getSpecialties(unitId?: number) {
    const url: string = unitId ? `${this.API}/units/specialties/${unitId}` : `${this.API}/units/specialties/${this.unit_id}`

    return this.http.get<any>(url)
      .pipe(
        take(1)
      );
  }

  getDoctorSpecialties() {
    return this.http.get<DoctorSpecialty[]>(`${this.API}/schedules/${this.CLINIC_ID}/doctorspecialties`)
      .pipe(
        take(1)
      );
  }

  getHealthUnits() {
    return this.http.get<HealthUnit[]>(`${this.API}/schedules/${this.CLINIC_ID}/healthunits`)
      .pipe(
        take(1)
      );
  }

  getHealthUnitRelationships() {
    return this.http.get<HealthUnitRelationship[]>(`${this.API}/schedules/${this.CLINIC_ID}/healthunitrelationships`)
      .pipe(
        take(1)
      );
  }

  getClinicalUnitPlanRelationships() {
    return this.http.get<ClinicalUnitPlanRelationship[]>(`${this.API}/schedules/${this.CLINIC_ID}/clinicalunitplanrelationships`)
      .pipe(
        take(1)
      );
  }

  getDoctorProfessionals(healthunitid?: number, specialtyid?: number, typeSpecialty?: string) {
    const url: string = healthunitid ? `${this.API}/schedules/${this.CLINIC_ID}/doctorprofessionals?SpecialtyId=${specialtyid}&HealthUnitId=${healthunitid}&TypeSpecialty=${typeSpecialty}` :
      `${this.API}/schedules/${this.CLINIC_ID}/doctorprofessionals`;
    return this.http.get<DoctorProfessional[]>(url)
      .pipe(
        take(1)
      );
  }

  getSpecialtyProfessionalTypes(healthunitid?: number) {
    return this.http.get<any>(`${this.API}/units/specialties/${healthunitid}`)
      .pipe(
        take(1)
      );
  }
}
